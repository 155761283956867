import React from 'react';
import PageLayout from '../../components/PageLayout/PageLayout';
import MainTitle from '../../components/Shared/MainTitle/MainTitle';

import {manifestoTitle} from '../../components/Manifesto/ManifestoHeader/header-content';
import ManifestoDescription from '../../components/Manifesto/ManifestoDescription/ManifestoDescription';
import SidedSection from '../../components/Shared/SidedSection/SidedSection';

import {manifestoSidedSections} from '../../components/Manifesto/ManifestoParagraphContent/manifesto-sided-sections';
import Paragraph from '../../components/Shared/Paragraph/Paragraph';

const Manifesto = () => {
    return (
        <PageLayout>
            <MainTitle title={manifestoTitle}/>
            <ManifestoDescription/>
            {manifestoSidedSections.map((s, k) => (
                <SidedSection title={s.title} key={k} reversed={!!(k % 2)} img={s.illustration}>
                    <Paragraph paragraph={s.desc} maxWidth="100%"/>
                </SidedSection>
            ))}
        </PageLayout>
    );
};
export default Manifesto;
