export const manifestoSidedSections = [
    {
        title: '1. Truth \nLook at the source',
        desc: 'We believe that photography is a profession that is vital to society: for its unique ability to capture reality, document the world and give individual expression to the human condition. As the world is digitized, technologies that advance the craft should be embraced — yet that must include a fundamental reengineering of industry practices that puts the author back at the center of the entire process.',
        illustration: '/images/manifesto/WIS2015001C-0026.jpg',
    },
    {
        title: '2. Openness\n& Ownership',
        desc: 'We believe that the best images want to be seen, but don’t want to be free. While the internet has resolved the first half of that equation, the challenge now is to find a way to value ownership without limiting openness.',
        illustration: null,
    },
    {
        title: '3. Protocol',
        desc: 'To ensure a reliable digital market for professional photographers and visual storytellers, a new technological protocol is required to manage their work that is separate and distinct from the mass of amateur images created and circulated online. We believe the blockchain offers the means to design such a solution.',
        illustration: null,
    },
    {
        title: '4. Trust',
        desc: 'The new protocol must guarantee authenticity, traceability and ethical standards.',
        illustration: '/images/manifesto/LIS015SE0055.jpg',
    },
    {
        title: '5. Scarcity',
        desc: 'The new protocol must also be designed to ensure economic viability for professionals seeking to make a living producing worthwhile images of quality: photojournalists, visual artists, fashion and celebrity, et. al. Blockchain offers the possibility, with NFTs as the most obvious proof of the potency of creating scarcity in the digital space.',
        illustration: null,
    },
    {
        title: '6. Fairness',
        desc: 'Photographers themselves, along with the news media, continue to finance the lion’s share of the best photography. Digital platforms that benefit from those images must contribute to the process.',
        illustration: null,
    },
    {
        title: '7. UX',
        desc: 'Ease of use for photographers, media, tech companies, audiences and private collectors is central to building a new protocol for digital images in the interests of all.',
        illustration: null,
    },
    {
        title: '8. Environment',
        desc: 'We have serious concerns about the energy currently required to power the blockchain, and will insist on a solution that is environmentally sustainable.',
        illustration: null,
    },
    {
        title: '9. Education',
        desc: 'Professionals have both a key role to play and much to gain by sharing knowledge about the place of images in society, and the ethics and economics necessary to sustain the medium.',
        illustration: '/images/manifesto/KUB2018002C-0065.jpg',
    },
    {
        title: '10. Collaboration',
        desc: 'Photographers, media, galleries, private companies, tech industry and the public sector must work together to build a new solution for the industry that is in the interest of society at large.',
        illustration: null,
    },
    {
        title: '11. Beauty',
        desc: ' It’s in the eye of the beholder. In the eye of the shooter. It’s on your computer screen, between your hands, on a gallery wall. It’s a person or a place, or one inside the other. Beauty is a moment captured in time. Yesterday. Right now. Forever.',
        illustration: null,
    },
    {
        title: '—30—',
        desc: ' After years of technology undermining the value and usurping control of their work, photographers have a unique opportunity in NFTs . We don’t claim to know where that market will take us. But our vision is for the long-term, and includes all work of value that professional photographers and visual storytellers produce. To make it a sustainable profession, we must reestablish scarcity in the digital world. It is to bring to life an idea that Ted Nelson, the inventor of the digital link, first proposed in the 1960s. Jaron Lanier recalled in his book You Are Not A Gadget: “He (Nelson) proposed that instead of copying digital media, we should effectively keep only one copy of each cultural expression and pay the author of that expression a small affordable amount whenever it is accessed.” Micropayments and million-dollar NFTs? Smartphones and social media, print sales and magazine covers? Artists and hacks and tk on a mission. Professional photography is dead. Long live professional photography.',
        illustration: null,
    },
]
