import './Header.scss';
import NavItem from './NavItem/NavItem';

import PixtLogo from '../../../assets/images/logos/logo_full-small.png';
import {navItems} from './items';

import {GiHamburgerMenu} from 'react-icons/gi';
import {GrClose} from 'react-icons/gr';
import {useState} from 'react';
import useCurrentEnv from '../../../hooks/useCurrentEnv';

const Header = () => {
    const [open, setOpen] = useState(false);
    const {isProdEnv} = useCurrentEnv();
    const logoLink = isProdEnv() ? 'http://nft.pixt.co' : '/';

    return (
        <header id="page-header">
            <nav className="navbar main-nav boxed-large-layout">
                <a className="logo-container" style={{display: "flex", alignItems: "center"}} href={logoLink}>
                    <img src={PixtLogo} alt="Logo Pixt"/>
                </a>
                <div className="toggle-container" onClick={() => setOpen(!open)}>
                    {!open
                        ? <GiHamburgerMenu/>
                        : <GrClose/>
                    }
                </div>
                <ul className={`links-container ${open ? 'is-open' : ''}`}>
                    {navItems.map((i, k) => <NavItem key={k} item={i}/>)}
                </ul>
            </nav>
        </header>
    );
};
export default Header;
