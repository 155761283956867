import {manifestoDescription} from '../ManifestoHeader/header-content';
import Paragraph from '../../Shared/Paragraph/Paragraph';

const ManifestoDescription = () => {
    return (
        <section className=" boxed-layout manifesto-descriptions">
            {manifestoDescription.map((p) => <Paragraph title={p.title} paragraph={p.paragraph} maxWidth="100%"/>)}
        </section>
    );
};
export default ManifestoDescription;
