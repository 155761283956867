import {CtaBtnLabel, CtaTitle} from './cta-manifesto-content';
import Cta from '../../Shared/Cta/Cta';
import useCurrentEnv from '../../../hooks/useCurrentEnv';

const CtaManifesto = () => {
    const {isProdEnv} = useCurrentEnv();
    const manifestoLink = isProdEnv() ? 'http://manifesto.pixt.co' : '/manifesto';

    return (
        <Cta style={{textAlign: "center"}}>
            <h3 style={{marginTop: "0"}}>{CtaTitle}</h3>
            <a className="button primary btn-default-height btn-default-width no-hover"
               style={{margin: "auto"}}
               href={manifestoLink}
            >
                {CtaBtnLabel}
            </a>
        </Cta>
    );
};
export default CtaManifesto;
