export const missionSection = {
    title: 'Our Mission',
    illustration: '/images/nft/BEN2016011N-0005.jpg',
    paragraphs: [
        {
            title: 'We believe in the power of photography.',
        },
        {
            paragraph: 'Others may measure that power in pixels or data or Instagram likes; we see it instead in the photographs that document injustice, the defining image that captures history, in stories told by photojournalists and visions captured by artists that can give meaning to a moment fixed in time and space. We’ve seen the medium travel from early Daguerretypes and Anna Atkins’ very first photo book to Henri Cartier-Bresson’s instant décisif, and Eddie Adams’ too; from the sad poignancy of the Migrant Mother in 1936 to the cruel truth of a young refugee boy washed up on the shore in 2015.',
        },
        {
            paragraph: 'There is of course more history to come, and more changes on the way, particularly as the digital revolution continues to accelerate. The advent of the internet and smartphones poses an unprecedented challenge to the professional photography industry: with some 1 billion images produced and consumed each day, it can sometimes appear that the amateurs are in charge. Yet we believe that the faster our world moves and the more of it is captured in images, the greater the importance of having the tools to support the professionals who can do it right.',
        },
        {
            paragraph: 'Our mission is to help build a future where professionally produced photographs and visual stories will be both open and profitable, accessible and secure, authenticated, traceable. They are pillars of a whole new paradigm for photography that will change how we consume stories and sustain storytellers in the quest for a more fruitful and equitable digital future.',
        },
    ]
};
