import React from 'react';
import Header from '../Shared/Header/Header';
import Footer from '../Shared/Footer/Footer';

const PageLayout = (props) => {
    return (
        <div>
            <Header/>
            <div className="page-content" style={{marginTop: "60px"}}>
                {props.children}
            </div>
            <Footer/>
        </div>
    );
};
export default PageLayout;
