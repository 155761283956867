import './SidedSection.scss';
import {useEffect, useState} from 'react';

const sideWidth = 55;

const SidedSection = ({title, children, reversed, img, bigIllustration}) => {
    const [illustrationHeight, setIllustrationHeight] = useState(img ? '400px' : '0');

    useEffect(() => {
        if (bigIllustration && img) {
            setIllustrationHeight('800px');
        }
    }, [bigIllustration, img]);

    return (
        <section className={`boxed-layout sided-section ${reversed ? 'reversed-section' : ''}`}
                 style={{
                     position: "relative",
                     display: "flex",
                     minHeight: illustrationHeight,
                     justifyContent: (reversed) ? "flex-end" : "flex-start"
                 }}
        >
            <div className={`max-width-${sideWidth}`}>
                <div className="title-container">
                    <h2>{title}</h2>
                    <div className="black-line"/>
                </div>
                {children}
            </div>
            {img &&
            <div className={`illustration-container max-width-${sideWidth}`}
                 style={{
                     height: illustrationHeight,
                     background: `url(${img}) #F3F5F7 50% center / cover no-repeat`,
                     top: "0",
                     right: reversed ? "initial" : "0",
                     left: reversed ? "0" : "initial",
                     zIndex: "-1",
                 }}
            />
            }
        </section>
    );
};
export default SidedSection;
