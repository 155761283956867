export const manifestoTitle = 'Eleven Points - A Manifesto for professional photography in the digital age:';

export const manifestoDescription = [
    {
        title: 'The future of professional photography begins now. It will look everything and nothing like the past.',
        paragraph: 'This unique medium of truth-telling and visual expression is more relevant than ever, as the world’s best photographers continue to burnish their images into our culture and onto our consciousness.\n' +
            'Yet as has happened before, technological change is triggering profound upheaval for the medium and the industry. In the nearly two centuries since its invention, we can point to certain moments when breakthrough innovations changed the course of photography’s history: the Daguerretypes of the 1840s. Anna Atkins setting photographs in a book for the first time in 1883, the advent of the motion picture and the arrival of Kodachrome film, the iPhone and Instagram.\n',
    },
    {
        title: 'We believe that 2021 will go down as another watershed year for photography.',
        paragraph: 'For the past two decades, even as digital technology has opened the medium to billions, as both producers and consumers, it has steadily been undermining the ethics and economics of the professional craft. The big tech companies have brought amazing innovation, but are sapping much of the value of the work from the authors who make the images that matter. In short, for too long now, the amateurs have been driving the destiny of the medium.',
    },
    {
        title: 'We believe that the blockchain can change the paradigm for the professional industry.',
        paragraph: 'This transparent and secure digital ledger provides a viable way to trace images across the internet, and reestablish scarcity on the marketplace. The emergence of NFT sales in 2021 has shown how the economic value of visual work can exist in digital form by reestablishing scarcity on the market and control with authors. Just as importantly, we now see the path through the same framework for a much broader and deeper economy that extends beyond the collector market. Yes, change is coming, and along with the world’s best photographers and those who believe in the power of visual storytelling, we hope you will join us around these 11 basic principles:',
    },
];
