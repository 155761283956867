import React from 'react';
import './Footers.scss';

import PixtFill from '../../../assets/images/logos/logo_t_white-small.png';
import PixtTrust from '../../../assets/images/logos/logo_patchphrase_white-small.png';

const Footer = () => {
    return (
        <footer id="page-footer">
            <div className="main-footer footer-content gl-col boxed-layout">
                <div className="footer-col gl-col-2">
                    <img src={PixtFill} alt="Logo Pixt - White T"/>
                    <p style={{fontSize: "18px"}}>&copy; Copyright 2021 - Pix.T</p>
                </div>
                <div className="footer-col gl-col-8" style={{textAlign: "center"}}>
                    <img src={PixtTrust} alt="Logo Pixt - T is for Trust"/>
                </div>
                <div className="footer-col gl-col-2">
                    <ul>
                        <li>Instagram</li>
                        <li>Twitter</li>
                        <li>Facebook</li>
                    </ul>
                </div>
            </div>
        </footer>
    )
};
export default Footer;
