const MainTitle = ({title, children, style}) => {
    return (
        <header className="boxed-layout" style={style}>
            {title &&
            <h1 className="main-title max-width-75 typo-size-bigger">
                {title}
            </h1>
            }
            {children}
        </header>
    );
};
export default MainTitle;
