import useCurrentEnv from '../../../../hooks/useCurrentEnv';

const NavItem = ({item}) => {
    const {isProdEnv} = useCurrentEnv();
    const finalLink = isProdEnv() ? item.prodLink : item.devLink;

    return (
        <li className="nav-item">
            <a href={finalLink}>
                {item.label}
            </a>
        </li>
    );
};
export default NavItem;
