import './CtaJoin.scss';
import Cta from '../../Shared/Cta/Cta';
import {CtaBtnLabel, CtaDescription, CtaTitle} from './cta-joint-content';
import Paragraph from '../../Shared/Paragraph/Paragraph';
import useCurrentEnv from '../../../hooks/useCurrentEnv';

const CtaJoin = () => {
    const {isProdEnv} = useCurrentEnv();
    const joinLink = isProdEnv() ? 'http://nft.pixt.co' : '/';

    return (
        <Cta className="cta-join-community" contentClassName="gl-col" style={{paddingBottom: "0"}}>
            <div className="gl-col-6">
                <div className="max-width-60">
                    <h3 style={{marginTop: "0"}}>{CtaTitle}</h3>
                    <a className="button primary btn-default-height btn-default-width no-hover"
                       href={joinLink}
                    >
                        {CtaBtnLabel}
                    </a>
                </div>
            </div>
            <div className="gl-col-6">
                <div className="cta-description-container" style={{paddingTop: "20px"}}>
                    <Paragraph className="cta-description" paragraph={CtaDescription} maxWidth="100%"/>
                </div>
            </div>
        </Cta>
    )
};
export default CtaJoin;
