import './Cta.scss';

const Cta = ({style, children, className, contentClassName}) => {
    return (
        <section className={`section-cta ${className ? className : ''}`}>
            <div className={`boxed-layout ${contentClassName ? contentClassName : ''}`}
                 style={{paddingTop: "5em", paddingBottom: "5em", ...style}}
            >
                {children}
            </div>
        </section>
    )
};
export default Cta;
