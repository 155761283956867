import React from 'react';
import PageLayout from '../../components/PageLayout/PageLayout';
import MainTitle from '../../components/Shared/MainTitle/MainTitle';
import Paragraph from '../../components/Shared/Paragraph/Paragraph';
import {aboutSubtitle, aboutTitle} from '../../components/About/AboutHeader/header-content';
import AboutIllustration from '../../components/About/AboutIllustration/AboutIllustration';
import CtaManifesto from '../../components/About/CtaManifesto/CtaManifesto';
import CtaJoin from '../../components/About/CtaJoin/CtaJoin';
import SidedSection from '../../components/Shared/SidedSection/SidedSection';
import {missionSection} from '../../components/About/AboutParagraphContent/mission-content';
import {marketsSection} from '../../components/About/AboutParagraphContent/markets-content';
import {valuesSection} from '../../components/About/AboutParagraphContent/values-content';

const About = () => {
    return (
        <PageLayout>
            <MainTitle title={aboutTitle} style={{marginBottom: "10em"}}>
                <Paragraph paragraph={aboutSubtitle}/>
            </MainTitle>
            <AboutIllustration/>
            <SidedSection title={missionSection.title} img={missionSection.illustration} bigIllustration>
                {missionSection.paragraphs.map((p) => (
                    <Paragraph title={p.title} paragraph={p.paragraph} maxWidth="100%"/>
                ))}
            </SidedSection>
            <CtaManifesto/>
            <SidedSection title={marketsSection.title} img={marketsSection.illustration} reversed>
                {marketsSection.paragraphs.map((p) => (
                    <Paragraph title={p.title} paragraph={p.paragraph} maxWidth="100%"/>
                ))}
            </SidedSection>
            <SidedSection title={valuesSection.title} img={valuesSection.illustration} bigIllustration>
                <Paragraph paragraph={valuesSection.paragraph} maxWidth="100%"/>
            </SidedSection>
            <CtaJoin/>
        </PageLayout>
    );
};
export default About;
