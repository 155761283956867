const Paragraph = ({title, paragraph, maxWidth, className}) => {
    return (
        <div className={`max-width-60 ${className ? className : ''}`}
             style={{maxWidth, whiteSpace: "pre-line"}}
        >
            {title &&
            <h5 className="phantom-bold">{title}</h5>
            }
            {paragraph &&
            <p dangerouslySetInnerHTML={{__html: paragraph}}/>
            }
        </div>
    )
};
export default Paragraph;
