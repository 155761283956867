import Paragraph from '../../Shared/Paragraph/Paragraph';
import {IllustrationLegend} from './illustration-content';
import React from 'react';

const AboutIllustration = () => {
    return (
        <section className="about-illustration">
            <div className={`illustration-container`}
                 style={{
                     height: "500px",
                     background: `url(/images/nft/WIS2015001C-0036.jpg) #F3F5F7 50% center / cover no-repeat`,
                 }}
            />
            <div className="boxed-layout" style={{margin: "3em auto 5em"}}>
                <Paragraph paragraph={IllustrationLegend} maxWidth={"100%"}/>
            </div>
        </section>
    );
};
export default AboutIllustration;
