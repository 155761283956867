export const marketsSection = {
    title: 'Our Markets',
    illustration: '/images/nft/BEN2016011N-0009.jpg',
    paragraphs: [
        {
            paragraph: 'Pix.T is an airtight-secure, easy-to-use technical protocol designed in the interest of photographers.',
        },
        {
            paragraph: 'Our unique blockchain solution guarantees transparency and reestablishes scarcity, restoring true value in top photography in both the digital and physical space. Without pretending to know how the market or practices will evolve, we are committed to pursuing and creating new opportunities for visual storytellers to pursue their craft both in the interest of the public and their own professional standing. Currently, these opportunities include but are not limited to:\n',
        },
        {
            paragraph: '*Exclusive access to NFT and limited edition print sales managed on the blockchain',
        },
        {
            paragraph: '*Licensing management, logistics and revenue sharing with full transparency and augmented automation across the entire media and social media ecosystem',
        },
        {
            paragraph: '*Micropayments',
        },
        {
            paragraph: `*Preventing digital image theft through blockchain-backed image embeds. (It’s time to kill the JPG!)`,
        },
        {
            paragraph: '*Visual literacy advocacy in the digital age',
        },
    ]
}
