export default function useCurrentEnv() {
    const url = window.location.toString();

    const isProdEnv = () => {
        return !(url.includes('127.0.0.1') || url.includes('localhost') || url.includes('42cloud'));
    }

    const isManifesto = () => {
        return url.includes('manifesto');
    }

    return {isProdEnv, isManifesto};
};
