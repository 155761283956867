import './App.css';

import {BrowserRouter as Router, Route, Switch,} from 'react-router-dom';
import About from './pages/About/About';
import Manifesto from './pages/Manifesto/Manifesto';
import useCurrentEnv from './hooks/useCurrentEnv';

function App() {
    const {isProdEnv, isManifesto} = useCurrentEnv();
    
    const routes = (
        !isProdEnv()
            ? <>
                <Route path="/" component={About} exact/>
                <Route path="/manifesto" component={Manifesto} exact/>
            </>
            : <Route path="/" component={isManifesto() ? Manifesto : About} exact/>
    );

    return (
        <Router>
            <Switch>
                <main role="main">
                    {routes}
                </main>
            </Switch>
        </Router>
    );
}

export default App;
